<template>
  <div class="other-chats">
    <InhouseChat :recipient-username="recipientUsername" />
  </div>
</template>

<script>
import { ref, onMounted, computed, onUnmounted } from "vue";
import axios from "@/utils/axios";
// import DropDown from "@/components/DropDown.vue";
import InhouseChat from "./InhouseChat.vue";

export default {
  name: "ChatsView",

  components: {
    // DropDown,
    InhouseChat,
  },

  props: {
    recipientUsername: {
      type: String,
      required: true,
    },
  },
  setup() {
    const integrationtypes = ref([]);
    const endpointUrl =
      "https://graph.facebook.com/v17.0/101592599705197/messages"; // Replace with your Facebook Graph API endpoint
    const accessToken =
      "EAAJRJL5NekUBOZCqgrdfNHGbDoAlhqqAo8DMHSinZAsBasRfZAdnNGz3ZAE5dsZA1VqRytHU7gd9NLnlX1KtdaE0vzJEFLph08lVN3e5UVyJyXxQnoxGEaov0dsgg4AiogZAcqz1N9SwZA9xuTDdWBvIu3tUPPzEFL7JVXt55RGzu1vVhdC1sZBZAmMNh8S2S8ZAVZAPHOs1ByAnow9jp0SCOHQ34XxZA5Dg5srs";

    const messengerendpointUrl =
      "https://graph.facebook.com/v17.0/101592599705197/messages"; // Replace with your Facebook Graph API endpoint
    const messengeraccessToken =
      "EAAJRJL5NekUBO0ZCQt7GJF3VNEPF3vbe0L84Po9T7OfnwVia4qzOcYbaw4ZCepxglLRAvkEET60O83bdGwSZBJj8DDYmiHQkyEug2ZB3Dv2hq1PjkljNC5oMFZCbzXLo924lcGZA9u7jygwH44XX4xVDde0rWOnwQVMVp3kZA8AYlbGLZBnZAk9ZA6rc9yGsyLtfSNZCxHyUuRjOtSbeZBmN5JJ5korSZAOssnBqVzQZDZD";

    const insagramendpointUrl =
      "https://graph.facebook.com/v17.0/101592599705197/messages"; // Replace with your Facebook Graph API endpoint
    const instagramaccessToken =
      "EAAJRJL5NekUBO0ZCQt7GJF3VNEPF3vbe0L84Po9T7OfnwVia4qzOcYbaw4ZCepxglLRAvkEET60O83bdGwSZBJj8DDYmiHQkyEug2ZB3Dv2hq1PjkljNC5oMFZCbzXLo924lcGZA9u7jygwH44XX4xVDde0rWOnwQVMVp3kZA8AYlbGLZBnZAk9ZA6rc9yGsyLtfSNZCxHyUuRjOtSbeZBmN5JJ5korSZAOssnBqVzQZDZD";

    const recipient = ref("");
    const template = ref({
      preview_url: false,
      body: "",
    });
    const response = ref(null);
    const messages = ref([]);

    // const fetchIncomingMessages = async () => {
    //     try {
    //         const response = await axios.get(
    //             "https://backend.thinkiqbs.com/whatsapp-messages/"
    //         );
    //         const data = response.data;
    //         console.log(data);
    //         messages.value = data;
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    const getIntegrationTypes = async () => {
      try {
        const response = await axios.get("/configs/integraation-types/");
        integrationtypes.value = response.data;
      } catch (error) {
        console.error(error);
      }
    };

    const fetchIncomingMessages = async () => {
      try {
        const response = await axios.get(
          "https://backend.bevacglobal.com/api/socials/incomingmessages/",
        );
        // const response = await axios.get("/socials/whatsapp-messages/");
        const data = response.data;

        if (Array.isArray(data)) {
          messages.value = data;
        } else {
          console.error("Invalid data format:", data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    // grouping

    const groupedMessages = computed(() =>
      groupMessagesBySender(messages.value),
    );

    const groupMessagesBySender = (messages) => {
      const groupedMessages = {};

      messages.value.forEach((message) => {
        const sender = message.message_from;
        if (!groupedMessages[sender]) {
          groupedMessages[sender] = [];
        }
        groupedMessages[sender].push({
          text: message.text,
          message_from: sender,
          isReceived: true,
        });
      });

      return groupedMessages;
    };

    // end of grouping

    // selecting thread

    const filteredMessages = ref([]);

    const selectThread = (messageFrom) => {
      filteredMessages.value = messages.value.filter(
        (message) => message.message_from === messageFrom,
      );
      let to = filteredMessages.value[0].message_from;
      recipient.value = to;
      /* eslint-disable */ console.log(
        ...oo_oo(
          `1568398235_131_12_137_13_4`,
          "check messages",
          messageFrom,
          filteredMessages.value,
          messages.value,
          recipient.value,
        ),
      );
    };

    let fetchInterval;

    onMounted(() => {
      // Fetch messages immediately on mount
      fetchIncomingMessages();

      // Then set up an interval to fetch messages every 1 minute
      fetchInterval = setInterval(fetchIncomingMessages, 60000);
    });

    onUnmounted(() => {
      clearInterval(fetchInterval);
    });

    const sendTemplateMessage = () => {
      const requestBody = {
        messaging_product: "whatsapp",
        recipient_type: "individual",
        to: recipient.value, // Replace 'PHONE_NUMBER' with your recipient's phone number
        type: "text",
        text: {
          preview_url: false,
          body: template.value.body,
        },
      };

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };

      const contact = {
        name: "John Doe",
        avatar: "path/to/avatar.jpg",
      };

      axios
        .post(endpointUrl, requestBody, { headers })
        .then((response) => {
          // Handle the response
          response.value = JSON.stringify(response.data, null, 2);
          /* eslint-disable */ console.log(
            ...oo_oo(`1568398235_181_20_181_40_4`, contact),
          );
        })
        .catch((error) => {
          // Handle errors here
          response.value = `Error: ${error.message}`;
        });

      // Assuming that the sent message should also appear in the chat interface
      messages.value.push({
        text: template.value.body,
        isReceived: false,
      });
    };

    // Create a computed property to get the unique "message_from" values
    const uniqueMessageFromList = computed(() => {
      const messageFromSet = new Set();
      messages.value.forEach((message) => {
        messageFromSet.add(message.message_from);
      });
      return Array.from(messageFromSet).map((messageFrom) => {
        // Find the first message object with the matching "message_from"
        return messages.value.find(
          (message) => message.message_from === messageFrom,
        );
      });
    });

    const input = ref("");
    const sendMessage = () => {
      messages.value.push("You: " + input.value);
      input.value = "";

      const requestBody = {
        messaging_product: "whatsapp",
        recipient_type: "individual",
        to: recipient.value, // Replace 'PHONE_NUMBER' with your recipient's phone number
        type: "text",
        text: {
          preview_url: false,
          body: template.value.body,
        },
      };

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };

      const contact = {
        name: "John Doe",
        avatar: "path/to/avatar.jpg",
      };

      axios
        .post(endpointUrl, requestBody, { headers })
        .then((response) => {
          // Handle the response
          response.value = JSON.stringify(response.data, null, 2);
          /* eslint-disable */ console.log(
            ...oo_oo(`1568398235_240_20_240_40_4`, contact),
          );
        })
        .catch((error) => {
          // Handle errors here
          response.value = `Error: ${error.message}`;
        });

      // Assuming that the sent message should also appear in the chat interface
      messages.value.push({
        text: template.value.body,
        isReceived: false,
      });

      template.value.body = "";

      // alert("Message sent!",input.value);

      setTimeout(() => {
        // messages.value.push("Bot: This is still work in progress.");
        fetchIncomingMessages();
      }, 1000);
    };

    onMounted(() => {
      getIntegrationTypes();
    });

    return {
      recipient,
      template,
      response,
      sendTemplateMessage,
      messages,
      groupedMessages,
      selectThread,
      uniqueMessageFromList,
      filteredMessages,
      // listUniqueMessageFrom,
      // filteredMessages
      messengerendpointUrl,
      messengeraccessToken,
      insagramendpointUrl,
      instagramaccessToken,
      sendMessage,
      input,
      fetchIncomingMessages,
      integrationtypes,
      getIntegrationTypes,
    };
  },
};
</script>

<style scoped>
/* Your component's styles here */
</style>
