<template>
  <div>
    <MainTemplate>
      <template #header>
        <h1 class="title">Notifications</h1>
      </template>

      <template #stats>
        <div class="stats-area">
          <h1 class="title">Notification Statistics</h1>
          <div class="metric">
            <p>{{ totalRecords }}</p>
            <span>Total Notifications</span>
          </div>
          <div class="metric">
            <p>{{ unreadNotifications }}</p>
            <span>Unread Notifications</span>
          </div>
        </div>
        <div class="header-bar">
          <input v-model="searchQuery" placeholder="Search notifications" />
          <button @click="fetchNotifications">Refresh Notifications</button>
        </div>
      </template>

      <template #content>
        <LoaderPage v-if="isLoading" />
        <div v-else class="">
          <table class="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Event Type</th>
                <th>Message</th>
                <th>Link</th>
                <th>Channel</th>
                <th>Urgency</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="notification in filteredNotifications" :key="notification.id" :class="notification.read ? 'read' : 'unread'">
                <td data-label="ID">{{ notification.id }}</td>
                <td data-label="Event Type">{{ notification.event_type }}</td>
                <td data-label="Message">
                  <div v-html="notification.message"></div>
                </td>
                <td data-label="Link">
                  <a :href="notification.link" target="_blank" v-if="notification.link">{{ notification.link }}</a>
                </td>
                <td data-label="Channel">{{ notification.channel }}</td>
                <td data-label="Urgency">{{ notification.urgency }}</td>
                <td data-label="Status">{{ notification.read ? 'Read' : 'Unread' }}</td>
                <td data-label="Actions">
                  <button class="btn btn-outline-secondary" @click="markAsRead(notification.id)">
                    <i class="bi bi-check-circle-fill"></i>
                  </button>
                  <button class="btn btn-outline-danger" @click="deleteNotification(notification.id)">
                    <i class="bi bi-trash-fill"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <PaginationControls :current-page="currentPage" :total-pages="totalPages" @prev="previousPage"
            @next="nextPage" />
        </div>
      </template>
    </MainTemplate>

    <Modal :show-modal="showModal" @close="closeModal">
      <template #header>
        <h4>{{ isEditing ? 'Edit Notification' : 'Add Notification' }}</h4>
      </template>
      <template #content>
        <form @submit.prevent="saveNotification">
          <div class="form-group">
            <label for="eventType">Event Type:</label>
            <input id="eventType" v-model="notificationForm.event_type" type="text" required />
          </div>
          <div class="form-group">
            <label for="message">Message:</label>
            <textarea id="message" v-model="notificationForm.message" required></textarea>
          </div>
          <div class="form-group">
            <label for="urgency">Urgency:</label>
            <select v-model="notificationForm.urgency" id="urgency">
              <option value="high">High</option>
              <option value="medium">Medium</option>
              <option value="low">Low</option>
            </select>
          </div>
          <button class="btn btn-primary" type="submit">
            {{ isEditing ? 'Update Notification' : 'Add Notification' }}
          </button>
        </form>
      </template>
    </Modal>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useNotificationStore } from '@/stores/notifications/NotificationStore';
import MainTemplate from '@/components/MainTemplate.vue';
import Modal from '@/components/Modal.vue';
import LoaderPage from '@/components/LoaderPage.vue';
import PaginationControls from '@/components/PaginationControls.vue';

export default {
  components: {
    MainTemplate,
    Modal,
    LoaderPage,
    PaginationControls,
  },
  setup() {
    const store = useNotificationStore();
    const showModal = ref(false);
    const isEditing = ref(false);
    const notificationForm = ref({
      event_type: '',
      message: '',
      urgency: 'medium',
    });

    const params = {

      currentPage: 1,

    }

    const fetchNotifications = () => {
      store.fetchNotifications();
    };

    

    const markAsRead = (id) => {
      store.markAsRead(id);
    };

    const deleteNotification = (id) => {
      store.deleteNotification(id);
    };

    const saveNotification = async () => {
      if (isEditing.value) {
        await store.updateNotification(notificationForm.value.id, notificationForm.value);
      } else {
        await store.createNotification(notificationForm.value);
      }
      closeModal();
    };

    const closeModal = () => {
      showModal.value = false;
      isEditing.value = false;
      notificationForm.value = { event_type: '', message: '', urgency: 'medium' };
    };

    const formatDate = (date) => {
      return new Date(date).toLocaleDateString();
    };

    const totalRecords = computed(() => store.totalRecords);
    const unreadNotifications = computed(() => {
      return store.notifications.filter(notification => !notification.read).length;
    });

    const filteredNotifications = computed(() => {
      return store.notifications.filter(notification =>
        notification.message.toLowerCase().includes(store.searchQuery.toLowerCase())
      );
    });

    const totalPages = computed(() => store.totalPages);
    const currentPage = computed(() => store.currentPage);

    const previousPage = () => {
      if (currentPage.value > 1) {
        store.currentPage--;
        fetchNotifications(params);
      }
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        store.currentPage++;
        fetchNotifications(params);
      }
    };

    onMounted(async () => {
      await fetchNotifications();
    });

    return {
      fetchNotifications,
      markAsRead,
      deleteNotification,
      saveNotification,
      closeModal,
      formatDate,
      totalRecords,
      unreadNotifications,
      filteredNotifications,
      totalPages,
      currentPage,
      previousPage,
      nextPage,
      showModal,
      isEditing,
      notificationForm,
    };
  },
};
</script>

<style scoped>
  
.unread {
  font-weight: bold;
}

</style>
