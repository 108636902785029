<!-- MainTemplate.vue -->

<template>
  <div>
    <div class="header-bar">
      <slot name="header">
        <input id="search" type="text" placeholder="Search" />
        <label for="dateToday" class="date">{{
          new Date().toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })
        }}</label>
      </slot>
    </div>

    <div class="body-area">
      <slot name="stats" />
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  name: "MainTemplate",
};
</script>

<style scoped>
/* Add your component-specific styles here if needed */
</style>
