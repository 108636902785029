import { createApp } from "vue";
import App from "./App.vue";
import { createPinia } from "pinia";
import router from "./router";
import "./assets/style.css";
import "./assets/crm/style.css";
import { BootstrapIconsPlugin } from "bootstrap-icons-vue";
import "bootstrap-icons/font/bootstrap-icons.css";

const app = createApp(App);

const pinia = createPinia();


const addCommaSeparator = (value) =>
    value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// Add mixins and mount the app
app.mixin({ methods: { addCommaSeparator } });

app.use(BootstrapIconsPlugin).use(router).use(pinia).mount("#app");
