<template>
  <div v-if="showModal" class="custom-modal">
    <div class="modal-content">
      <div class="modal-header">
        <slot name="header">
          <span class="custom-header">Custom Header</span>
        </slot>
        <span class="close" @click="closeModal">&times;</span>
      </div>
      <div class="modal-body">
        <slot name="content">
          <p class="custom-content">Custom Content</p>
        </slot>
      </div>
      <div class="modal-footer">
        <!-- <slot name="footer">
                    <button @click="closeModal" class="custom-footer">Close</button>
                </slot> -->
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";

export default {
  name: "ModalView",
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  setup(props,{
    emit}) {
    const modalVisible = ref(props.showModal);

    watch(() => {
      modalVisible.value = props.showModal;
    });

    const closeModal = () => {
      // alert("Close Modal");
      // Emit the event to the parent component
      emit("close-modal");
      console.log("Close Modal", modalVisible.value);
      if (props.onClose) {
        props.onClose();
      }
    };

    onMounted(() => {
      modalVisible.value = props.showModal;
    });

    return {
      modalVisible,
      closeModal,
    };
  },
};
</script>
