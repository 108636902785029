<!-- ChatComponent.vue -->

<template>
  <div
    v-for="incomingmessage in incomingmessages"
    :key="incomingmessage.id"
    class="list-chats"
  >
    <!-- {
        "id": 1,
        "contact_wa_id": "1234567890",
        "message_text": "Hello, this is a test message from WhatsApp!",
        "received_at": "2024-04-21T09:52:52.613436Z"
        }, -->

    {{ incomingmessage.message_text }}
    {{ incomingmessage.received_at }}
    {{ incomingmessage.contact_wa_id }}
  </div>

  <div class="inhouse-chat">
    <strong>{{ recipientUsername }}</strong>
    <div class="message-list">
      <div v-for="message in messages" :key="message.id">
        <p>{{ message.content }}</p>
        <small
          >{{ message.sender }} -
          {{ formatTimestamp(message.timestamp) }}</small
        >
      </div>
    </div>

    <div class="message-input">
      <textarea v-model="newMessage" placeholder="Type your message..." />
      <button @click="sendMessage">Send</button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import axios from "@/utils/axios";

export default {
  props: {
    recipientUsername: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    // Reactive state
    const messages = ref([]);
    const newMessage = ref("");
    const incomingmessages = ref([]);

    const getIncomingMessages = async () => {
      try {
        const response = await axios.get(`/socials/incomingmessages/`);
        incomingmessages.value = response.data;
      } catch (error) {
        console.error("Error fetching incoming messages:", error);
      }
    };

    // Methods
    const formatTimestamp = (timestamp) => {
      // You can use a date formatting library like moment.js here
      return new Date(timestamp).toLocaleString();
    };

    const sendMessage = async () => {
      try {
        const response = await axios.post("/socials/outgoingmessages/", {
          receiver: props.recipientUsername,
          // receiver:"nmbajah@gmail.com",
          content: newMessage.value,
        });

        messages.value.push(response.data);

        // Clear the input field
        newMessage.value = "";
      } catch (error) {
        console.error("Error sending message:", error);
      }
    };

    const fetchMessages = async () => {
      try {
        const response = await axios.get("/socials/incomingmessages/");
        messages.value = response.data;
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    // Fetch messages when the component is mounted
    onMounted(() => {
      // setInterval(fetchMessages, 10000);
      fetchMessages();
      getIncomingMessages();
    });

    // Return values to be used in the template
    return {
      messages,
      newMessage,
      formatTimestamp,
      sendMessage,
      incomingmessages,
      getIncomingMessages,
    };
  },
};
</script>

<style scoped>
/* Add your styles here */
.chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.message-list {
  flex: 1;
  overflow-y: auto;
}

.message-input {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

textarea {
  flex: 1;
  margin-right: 10px;
}
</style>
