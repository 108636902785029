<template>
  <div v-if="isLoggedIn" class="sidenav">
    <button class="sidenav-toggle" @click="toggleNavShow">
      <i class="fa-solid fa-bars" />
    </button>
    <ul class="main-sidenav">
      <li v-for="(item, index) in menuItems" :key="index">
        <a @click="toggleNavShow(item.title)">
          <component :is="item.icon" /> {{ item.title }}
        </a>
        <div v-show="navshow === item.title" class="sub-sidenav">
          <ul>
            <li v-for="(subItem, subIndex) in item.sub_items" :key="subIndex" @click="navigate(subItem.path)">
              <a>
                <component :is="subItem.icon" v-if="subItem.icon" /> {{ subItem.title }}
              </a>
            </li>
          </ul>
        </div>
      </li>
      <a @click="navigate('/settings')"><b-icon-briefcase-fill /> Settings</a>
    </ul>
  </div>
</template>


<script>
  import { ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router'; // Import the router
  import axios from '@/utils/axios';

  export default {
    setup() {
      const isLoggedIn = ref(true); // Change this based on your authentication logic
      const navshow = ref('');
      const menuItems = ref([]);
      const router = useRouter(); // Get the router instance

      const toggleNavShow = (name) => {
        navshow.value = navshow.value === name ? '' : name;
      };

      const navigate = (route) => {
        if (route) {
          // Use router.push to navigate to the route without reloading
          router.push(route).catch(err => console.error('Navigation error:', err));
        }
      };

      const getNavItems = async () => {
        if (isLoggedIn.value) {
          // Fetch and set menuItems
          try {
            const response = await axios.get('/config/menu-items/', {
              params: {
                has_sub_items: true
              }
            });
            menuItems.value = response.data.results;
          } catch (error) {
            console.error('Error fetching menu items:', error);
          }
        }
      };

      onMounted(() => {
        getNavItems();
      });

      return { isLoggedIn, navshow, menuItems, toggleNavShow, navigate };
    },
  };

</script>

<style>
  .sidenav {
    width: 220px;
    background-color: #333;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    overflow-y: auto;
    transition: width 0.3s ease;
  }

  .sidenav-toggle {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #2980b9;
    border: none;
    color: white;
    padding: 10px;
    cursor: pointer;
  }

  .main-sidenav ul {
    list-style-type: none;
    padding: 0;
  }

  .main-sidenav li {
    padding: 10px;
    cursor: pointer;
  }

  .main-sidenav li a {
    color: white;
    text-decoration: none;
    display: block;
  }

  .sub-sidenav {
    padding-left: 15px;
    background-color: #333;
  }

  .sub-sidenav ul {
    list-style-type: none;
    padding: 0;
  }

  .sub-sidenav li a {
    color: #ecf0f1;
    text-decoration: none;
  }

</style>
