import axios from 'axios';

const instance = axios.create ({
  baseURL: getBaseUrl (),
});

function getBaseUrl () {
  const hostname = window.location.hostname;
  console.log ('hostname', hostname);

  const baseUrls = {
    'app.bevcglobal.com': 'https://backend.bevcglobal.com/api/',
    'web.bevcglobal.com': 'https://backend.bevcglobal.com/api/',
    'app.paanguzo.com': 'https://backend.paanguzo.com/api/',
    'web.paanguzo.com': 'https://backend.paanguzo.com/api/',
  };

  return baseUrls[hostname] || 'http://127.0.0.1:8000/api/';
}


// List of routes where headers should be disabled
const routesWithoutHeaders = [
  '/accounts/signup/verify/?code=',
  '/accounts/login',
  '/accounts/signup/',
  '/accounts/password/reset/',
  '/accounts/password/reset/confirm/',
  'https://backend.thinkiqbs.com/api/socials/whatsapp-messages/',
];

// Permission routes and required permissions
const permissionRoutes = {
  '/projects/': 'view_project',
  // Add more routes and corresponding permissions here
};

instance.interceptors.request.use (
  config => {
    // Disable headers for specific routes
    const disableHeaders = routesWithoutHeaders.some (route =>
      config.url.includes (route)
    );

    if (!disableHeaders) {
      config.headers.Authorization = `Token ${localStorage.getItem ('appToken')}`;
    }

    return config;
  },
  error => {
    return Promise.reject (error);
  }
);

instance.interceptors.response.use (
  response => {
    const url = response.config.url;
    const requiredPermission = Object.keys (permissionRoutes).find (route =>
      url.includes (route)
    );

    if (requiredPermission) {
      const userPermissions = response.data.user_permissions || [];

      if (!userPermissions.includes (permissionRoutes[requiredPermission])) {
        alert (`You do not have permission to access this resource.`);
        return Promise.reject ('Permission denied');
      }
    }

    return response;
  },
  error => {
    return Promise.reject (error);
  }
);

export default instance;
