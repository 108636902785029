import { defineStore } from 'pinia';
import axios from '@/utils/axios';
import { computed, ref } from 'vue';

export const useNotificationStore = defineStore('notifications', () => {
  const notifications = ref([]);
  const isNotificationsLoaded = ref(false);
  const currentPage = ref(1);
  const totalPages = ref(1);
  const itemsPerPage = ref(50);
  const searchQuery = ref('');
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const errorMessage = ref(null);

  const fetchNotifications = async () => {
    isLoading.value = true;
    errorMessage.value = null;
    try {
      const response = await axios.get('/notifications/notifications/', {
        params: {
          page: currentPage.value,
          search: searchQuery.value,
          page_size: itemsPerPage.value,
        },
      });
      notifications.value = [...response.data.results];
      totalRecords.value = response.data.count;
      totalPages.value = Math.ceil(totalRecords.value / itemsPerPage.value);
      isNotificationsLoaded.value = true;
    } catch (error) {
      console.error('Error fetching notifications:', error);
      errorMessage.value = 'Failed to fetch notifications. Please try again later.';
    } finally {
      isLoading.value = false;
    }
  };

  const fetchAllNotifications = async () => {
    isLoading.value = true;
    errorMessage.value = null;
    const allNotifications = [];
    let page = 1;

    try {
      while (page <= totalPages.value) {
        const response = await axios.get('/notifications/notifications/', {
          params: {
            page,
            search: searchQuery.value,
            page_size: itemsPerPage.value,
          },
        });

        allNotifications.push(...response.data.results);
        totalRecords.value = response.data.count;
        totalPages.value = Math.ceil(totalRecords.value / itemsPerPage.value);

        page++;
      }
      notifications.value = allNotifications; // Store all fetched notifications
      isNotificationsLoaded.value = true;
    } catch (error) {
      console.error('Error fetching all notifications:', error);
      errorMessage.value = 'Failed to fetch all notifications. Please try again later.';
    } finally {
      isLoading.value = false;
    }
  };

  const markAsRead = async (notificationId) => {
    try {
      await axios.patch(`/notifications/notifications/${notificationId}/`, {
        read: true,
      });
      const index = notifications.value.findIndex(notification => notification.id === notificationId);
      if (index !== -1) {
        notifications.value[index].read = true; // Update the read status locally
      }
    } catch (error) {
      console.error('Failed to mark notification as read:', error);
      errorMessage.value = 'Failed to mark notification as read. Please try again later.';
    }
  };

  const createNotification = async (notification) => {
    try {
      const response = await axios.post('/notifications/notifications/', notification);
      notifications.value.push(response.data);
    } catch (error) {
      console.error('Failed to create notification:', error);
      errorMessage.value = 'Failed to create notification. Please check the input and try again.';
    }
  };

  const updateNotification = async (notificationId, updatedNotification) => {
    try {
      const response = await axios.put(`/notifications/notifications/${notificationId}/`, updatedNotification);
      const index = notifications.value.findIndex(notification => notification.id === notificationId);
      if (index !== -1) {
        notifications.value[index] = Object.assign({}, notifications.value[index], response.data);
      }
    } catch (error) {
      console.error('Failed to update notification:', error);
      errorMessage.value = 'Failed to update notification. Please try again later.';
    }
  };

  const deleteNotification = async (notificationId) => {
    try {
      await axios.delete(`/notifications/notifications/${notificationId}/`);
      notifications.value = notifications.value.filter(notification => notification.id !== notificationId);
    } catch (error) {
      console.error('Failed to delete notification:', error);
      errorMessage.value = 'Failed to delete notification. Please try again later.';
    }
  };

  const progressBar = computed(() => {
    const total = totalRecords.value;
    const current = notifications.value.length || 0;
    const width = total ? `${(current / total) * 100}%` : '0%';
    return { width };
  });

  return {
    notifications,
    isLoading,
    currentPage,
    totalPages,
    itemsPerPage,
    fetchNotifications,
    fetchAllNotifications,
    markAsRead,
    createNotification,
    updateNotification,
    deleteNotification,
    searchQuery,
    totalRecords,
    progressBar,
    errorMessage,
    isNotificationsLoaded,
  };
});
