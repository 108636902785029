import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/SettingsView.vue'),
  },
  {
    path: '/spvs',
    name: 'spvs',
    component: () => import('../views/financing/SpvListing.vue'),
  },

  {
    path: '/financialinstruments',
    name: 'financialinstruments',
    component: () => import('../views/financing/FinancialInstruments.vue'),
  },

  {
    path: '/financesettings',
    name: 'financesettings',
    component: () => import('../views/financing/FinancingSettings.vue'),
  },

  {
    path: '/debtfinancing',
    name: 'debtfinancing',
    component: () => import('../views/financing/DebtFinancing.vue'),
    props: route => ({ value: route.query.value }),
  },

  {
    path: '/projectfunding',
    name: 'projectfunding',
    component: () => import('../views/financing/ProjectFunding.vue'),
    props: route => ({ value: route.query.value }),

  },

  {
    path: '/budgetlisting',
    name: 'budgetlisting',
    component: () => import('../views/financing/BudgetListings.vue'),
    props: route => ({ value: route.query.value }),

  },

  {
    path: '/projectdetails/:id',
    name: 'projectdetails',
    component: () => import('../views/financing/ProjectDetails.vue'),
    // props: route => ({value: route.query.value}),
    props: true,

  },

  {
    path: '/milestonedetails/:id',
    name: 'milestonedetails',
    component: () => import('../views/financing/ProjectMilestoneDetails.vue'),
    // props: route => ({value: route.query.value}),
    props: true,

  },

  {
    path: '/projectmaterials/:id',
    name: 'projectmaterials',
    component: () => import('../views/financing/ProjectMaterials.vue'),
    // props: route => ({value: route.query.value}),
    props: true,

  },

  {
    path: '/contributionsystem',
    name: 'contributionsystem',
    component: () => import('../views/financing/ContributionSystem.vue'),
    props: route => ({ value: route.query.value }),

  },

  {
    path: '/spvDetails/:id',
    name: 'spvDetails',
    component: () => import('../views/financing/SpvDetails.vue'),
    props: true,
  },
  {
    path: '/instrumentsDetails/',
    name: 'instrumentsDetails',
    component: () => import('../views/financing/FinanceInstrumentDetails.vue'),
    props: true,
  },
  {
    path: '/funding-providers',
    name: 'financing_provider',
    component: () => import('../views/financing/ProvidersListing.vue'),
  },

  {
    path: '/financing',
    name: 'financing',
    component: () => import('../views/financing/FinancingOptions.vue'),
  },
  {
    path: '/projects',
    name: 'project',
    component: () => import('../views/financing/ProjectListing.vue'),
  },



  {
    path: '/loan-applications',
    name: 'loan-applications',
    component: () => import('../views/financing/LoanApplicationListing.vue'),

  },
  {
    path: '/materials',
    name: 'materials',
    component: () => import('../views/materials/MaterialsListings.vue'),
  },
  {
    path: '/materials-details/:id',
    name: 'materials-details',
    component: () => import('../views/materials/MaterialDetails.vue'),
    props: true,
  },

  {
    path: '/specifications',
    name: 'specifications',
    component: () => import('../views/materials/MaterialSpecifications.vue'),
  },

  {
    path: '/specificationsdetails/:id',
    name: 'specificationsdetails',
    component: () => import('../views/materials/MaterialsSpecificationsDetails.vue'),
    props: true,
  },

  {
    path: '/components',
    name: 'components',
    component: () => import('../views/materials/MaterialComponents.vue'),
  },

  {
    path: '/componentsdetails/:id',
    name: 'componentsdetails',
    component: () => import('../views/materials/MaterialComponentsDetails.vue'),
    props: true,

  },

  {
    path: '/building_elements',
    name: 'building_elements',
    component: () => import('../views/materials/MaterialBuildingElement.vue'),
  },
  {
    path: '/materials_catagory',
    name: 'materials_catagory',
    component: () => import('../views/materials/MaterialCategories.vue'),
  },
  {
    path: '/property',
    name: 'property',
    component: () => import('../views/property/PropertyListings.vue'),
  },
  {
    path: '/property/:id',
    name: 'property-details',
    component: () => import('../views/property/PropertyDetails.vue'),
    props: true,
  },
  {
    path: '/property-settings',
    name: 'property-settings',
    component: () => import('../views/property/PropertySettings.vue'),
  },
  {
    path: '/rentals',
    name: 'rentals',
    component: () => import('../views/rentals/RentalListings.vue'),
  },
  {
    path: '/subscriptions',
    name: 'subscriptions',
    component: () => import('../views/subscriptions/SubscriptionsList.vue'),
  },

  {
    path: '/subscriptionsdetails/:id',
    name: 'subscriptionsdetails',
    component: () =>
      import('../views/subscriptions/SubscriptionPlanDetails.vue'),
    props: true,
  },

  {
    path: '/jobs',
    name: 'jobs',
    component: () => import('../views/jobs/JobsListing.vue'),
  },
  {
    path: '/jobsdetail/:id/',
    name: 'jobsdetails',
    component: () => import('../views/jobs/JobsListing.vue'),
  },

  {
    path: '/jobs-item',
    name: 'jobs-item',
    component: () => import('../views/jobs/JobItems.vue'),
  },
  {
    path: '/jobstype',
    name: 'jobstype',
    component: () => import('../views/jobs/JobTypes.vue'),
  },

  {
    path: '/jobscategory',
    name: 'jobscategory',
    component: () => import('../views/jobs/JobCategory.vue'),
  },
  {
    path: '/Jobsroles',
    name: 'Jobsroles',
    component: () => import('../views/jobs/JobRoles.vue'),
  },
  {
    path: '/signup/verify',
    name: 'SignupVerify',
    component: () => import('@/views/SignupVerify.vue'),
    props: route => ({ code: route.query.code }),
    meta: {
      hideSideBar: true,
    },
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('@/views/SignupPage.vue'),
    meta: {
      hideSideBar: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/LoginPage.vue'),
    meta: {
      hideSideBar: true,
    },
  },

  {
    path: '/locations',
    name: 'locations',
    component: () => import('../views/configs/LocationsPage.vue'),
    meta: {
      showHeader: false,
      showNavBar: true,
    },
  },
  {
    path: '/county',
    name: 'county',
    component: () => import('../views/configs/CountyInformation.vue'),
    meta: {
      showHeader: false,
      showNavBar: true,
    },
  },

  // CRM section\

  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('../views/crm/ContactsView.vue'),
  },

  {
    path: '/leads',
    name: 'leads',
    component: () => import('../views/crm/LeadsView.vue'),
  },
  {
    path: '/inbox',
    name: 'inbox',
    component: () => import('../views/crm/InboxView.vue'),
  },
  {
    path: '/tickets',
    name: 'tickets',
    component: () => import('../views/crm/TicketsView.vue'),
  },
  {
    path: '/ticket/:id',
    name: 'ticket-details',
    component: () => import('../views/crm/TicketDetails.vue'),
    props: true,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/crm/DashboardView.vue'),
  },

  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('../views/configs/NotificationsPage.vue'),
  },

  {
    path: '/configure',
    name: 'configure',
    component: () => import('../views/crm/ConfigureView.vue'),
  },
  {
    path: '/organizations',
    name: 'organizations',
    component: () => import('../views/crm/OrganizationView.vue'),
  },

  {
    path: '/myaccount',
    name: 'myaccount',
    component: () => import('../views/crm/MyaccountPage.vue'),
    meta: {
      hideNavbar: false,
      hideSideBar: true,
    },
  },
  {
    path: '/customer/:id',
    name: 'customer-details',
    component: () => import('../views/crm/CustomerDetails.vue'),
    props: true,
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: () => import('../views/crm/TasksView.vue'),
  },
  {
    path: '/tasks/:id',
    name: 'task-details',
    component: () => import('../views/crm/TaskDetails.vue'),
    props: true,
  },
  {
    path: '/notes',
    name: 'notes',
    component: () => import('../views/crm/NotesView.vue'),
  },
  {
    path: '/calls',
    name: 'calls',
    component: () => import('../views/crm/CallsView.vue'),
  },
  {
    path: '/sms',
    name: 'sms',
    component: () => import('../views/crm/SmsView.vue'),
  },
  {
    path: '/chats',
    name: 'chats',
    component: () => import('../views/crm/ChatsView.vue'),
  },
  {
    path: '/activities',
    name: 'activities',
    component: () => import('../views/crm/ActivitiesView.vue'),
  },
  {
    path: '/interactions',
    name: 'interactions',
    component: () => import('../views/crm/InteractionsView.vue'),
  },
  {
    path: '/salesview',
    name: 'salesview',
    component: () => import('../views/crm/SalesView.vue'),
  },
  {
    path: '/marketingview',
    name: 'marketingview',
    component: () => import('../views/crm/MarketingView.vue'),
  },

  {
    path: '/marketing-listdetails/:id',
    name: 'MarketingListDetails',
    component: () => import('../views/crm/MarketingListDetails.vue'),
    props: true,
  },
  {
    path: '/knowledgebase',
    name: 'knowledgebase',
    component: () => import('../views/crm/KnowledgeBaseView.vue'),
  },
  {
    path: '/webconfig',
    name: 'webconfig',
    component: () => import('../web/WebConfig.vue'),
  },
  {
    path: '/suppliers',
    name: 'suppliers;',
    component: () => import('../views/suppliers/SuppliersList.vue'),
  },

  {
    path: '/suppliers/:id',
    name: 'SupplierDetails',
    component: () => import('../views/suppliers/SupplierDetails.vue'), // Adjust the path if needed
    props: true, // Enables passing route params as props to the component
  },

  {
    path: '/catalogues',
    name: 'catalogues;',
    component: () => import('../views/suppliers/CatalogueList.vue'),
  },
  {
    path: '/cataloguesdetails/:id',
    name: 'cataloguesdetails;',
    component: () => import('../views/suppliers/CatalogueDetails.vue'),
    props: true,


  },
  {
    path: '/pricelist',
    name: 'pricelist',
    component: () => import('../views/materials/PriceLists.vue'),
  },
  {
    path: '/pricelistdetails/:id',
    name: 'pricelistdetails',
    component: () => import('../views/materials/PriceListDetails.vue'),
    props: true,
  },
  {
    path: '/customer-list',
    name: 'customer-list',
    component: () => import('../views/customer/CustomerList.vue'),
  },

  {
    path: '/:catchAll(.*)',
    component: () => import('../views/ComingSoon.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
