<template>
  <div class="app">
    <header v-if="!hideNavbar">
      <!-- Header content goes here -->
      <NavBar />
    </header>

    <div class="container">
      <aside v-if="!hideSideBar && isLoggedIn" class="col-1">
        <!-- Side nav content goes here -->
        <SideNav />
      </aside>

      <main class="main">
        <!-- Body content goes here -->

        <router-view />
      </main>
    </div>

    <footer>
      <!-- Footer content goes here -->
      <!-- <FooterBar /> -->My
    </footer>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
// import FooterBar from "./components/FooterBar.vue";
import SideNav from "@/components/SideNav.vue";
import { ref, watch, computed } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "App",
  components: {
    NavBar,
    // FooterBar,
    SideNav,
  },

  setup() {
    const hideNavbar = ref(false);
    const hideSideBar = ref(false);
    const $route = useRoute();
    const authToken = computed(() => localStorage.getItem("appToken"));

    const isLoggedIn = computed(() => authToken.value !== null);

    watch(
      () => $route.meta,
      (meta) => {
        hideNavbar.value = meta.hideNavbar || false;
        hideSideBar.value = meta.hideSideBar || false;
      },
      { immediate: true },
    );

    return {
      hideNavbar,
      hideSideBar,
      isLoggedIn,
      authToken,
    };
  },
};
</script>

<style scoped>
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: rgb(82, 82, 80);
}

.container {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin-top: 0;
  /* padding-top: 5px; */
  height: 100vh;
  overflow: scroll;
  /* border: 11px solid #d81818; */
  /* padding: 10px; */
}

.sidenav {
  flex: 1 0 0 auto;
  height: 100vh;
  overflow: scroll;
  padding: 20px;
  margin: 0;

}

.main {
  width: 100%;
  flex: 1 1 0;
  /* padding: 20px; */
  margin: 0;
  overflow: scroll;
  /* border: 11px solid #d81818; */
  background: radial-gradient(
    circle,
    rgba(248, 248, 248, 1) 0%,
    rgba(233, 189, 58, 1) 84%,
    rgba(247, 247, 247, 1) 100%
  );

}

header {
  /* Add your styles for the header */
  width: 100%;
  height: 50px;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
}

footer {
  /* Add your styles for the footer */
  width: 100%;
  height: 50px;
  /* } */

  /* Add your responsive styles here */
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
    }

    .sidenav {
      display: none;
      border: var(--PrimaryColor) 1px solid;
    }
  }
}
</style>
